import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { weng888_initStrategies } from './weng888_initStrategies';
import { weng888_initStyling } from './weng888_initStyling';
import { Weng888_TopBar } from './weng888_Topbar';
import { Weng888_SidePane } from './weng888_SidePane';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
import { component } from '~/utils/component';
import { Fragment } from 'react';
import { useStartFilteringIndicators } from '~/pages/weng888/hooks/useStartFilteringIndicators';
import { BsChevronLeft } from 'react-icons/bs';
import Fr_WithDrawerCloseButton from '~/pages/heineken_template/_fr/fr_WithDrawerCloseButton';
import { InvestmentLoginView } from '~/modules/investment-consultant/loginPage/InvestmentLoginView';
export const weng888_init = (templateProps) => {
    weng888_initStrategies();
    weng888_initStyling(templateProps);
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode(useThemeStore.getState().theme);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    templateProps.layout.Providers = component((props) => {
        useStartFilteringIndicators();
        return <Fragment>{props.children}</Fragment>;
    });
    templateProps.layout.login = (<InvestmentLoginView copyright='亞洲證券投顧(109)金管投顧新字第022號 Copyright © 2023' activationCode={false}/>);
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TX-1',
        interval: 5,
        overrides: store.charting.darkOverrides,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr;
    user-select: none;

    .MuiModal-backdrop {
      background-color: transparent;
    }

    grid-template-areas:
      'Row1 Row1'
      'Drawer1 Chart'
      'Drawer1 Chart';

    ${createIPadCss(css `
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Weng888_TopBar;
    templateProps.layout.Drawer1 = (<Fr_WithDrawerCloseButton.Display right Button={<BsChevronLeft css={css `
            #__html {
              position: relative;
              z-index: 100;
            }
            color: #ffffff;
            background-color: #42948888;
            border-radius: 5px;
            height: 64px;
            width: 28px;
            font-size: 24px;
          `}/>}>
      <Weng888_SidePane />
    </Fr_WithDrawerCloseButton.Display>);
    templateProps.hooks.add(useSignalrStart2_0);
};
