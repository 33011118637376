import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { store } from '~/pages/heineken_template/_private/store';
import { weng888_strategiesGroup } from '~/pages/weng888/weng888_initStrategies';
/** 散戶在圖表內直接操作 charting 時，對指標群作出加載或移除 */
export const useStartFilteringIndicators = () => {
    const symbol = useSnapshot(store.charting).symbol;
    useEffect(() => {
        store.charting.replaceIndicators(weng888_strategiesGroup.main.indicators);
    }, [symbol]);
};
