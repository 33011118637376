/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { weng888_store } from '~/pages/weng888/weng888_store';
export const sar0 = createIndicator({
    id: 'sar0',
    displayName: '力道撐壓線',
    // enabledOn(symbol, data, channel) {
    //   return data?.type === channel.tw_futures || data?.type === channel.os_futures
    // },
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            //this._input = [, 0.026, 0.026, 0.1, 0.1, 0.1];
            const sar0_p0 = 0.025; /*this._input(0)*/
            const sar1_p0 = 0.105; /*this._input(1)*/
            const close = this.PineJS.Std.close(this._context);
            const sar_value0 = this.PineJS.Std.sar(sar0_p0, sar0_p0, sar0_p0, this._context);
            const sar_value1 = this.PineJS.Std.sar(sar1_p0, sar1_p0, sar1_p0, this._context);
            let vs0a, vs0b, vs1a, vs1b;
            if (this.PineJS.Std.close(this._context) > sar_value0) {
                vs0a = sar_value0;
                vs0b = NaN;
            }
            else {
                vs0a = NaN;
                vs0b = sar_value0;
            }
            if (this.PineJS.Std.close(this._context) > sar_value1) {
                vs1a = sar_value1;
                vs1b = NaN;
            }
            else {
                vs1a = NaN;
                vs1b = sar_value1;
            }
            if (close >= vs0a && close >= vs1a) {
                weng888_store.trendState = 2;
            }
            else if (close >= vs0a && close < vs1b) {
                weng888_store.trendState = 1;
            }
            else if (close < vs0b && close < vs1b) {
                weng888_store.trendState = -2;
            }
            else if (close < vs0b && close >= vs1a) {
                weng888_store.trendState = -1;
            }
            return [vs0a, vs0b, vs1a, vs1b];
        },
    },
    metainfo: {
        _metainfoVersion: 42,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            { id: 'plot_2', type: 'line' },
            { id: 'plot_3', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 3,
                    plottype: 3,
                    trackPrice: false,
                    transparency: 70,
                    color: '#ff0000',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 3,
                    plottype: 3,
                    trackPrice: false,
                    transparency: 70,
                    color: '#009000',
                },
                plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 30,
                    color: '#ff2ef8',
                },
                plot_3: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    plottype: 6,
                    trackPrice: false,
                    transparency: 30,
                    color: '#00ff00',
                },
            },
            // Precision is 0 digit, only integer
            precision: 0,
            inputs: {
            /*in_0: 0.026, in_1: 0.1*/
            },
        },
        styles: {
            plot_0: {
                title: 'A1',
                histogramBase: 0,
            },
            plot_1: {
                title: 'A2',
                histogramBase: 0,
            },
            plot_2: {
                title: 'B1',
                histogramBase: 0,
            },
            plot_3: {
                title: 'B2',
                histogramBase: 0,
            },
        },
        inputs: [
        /*{
                id: 'in_0',
                name: 'in_0',
                defval: 0.026,
                type: 'float',
                min: 0.001,
                max: 2e3,
              },
              {
                id: 'in_1',
                name: 'in_1',
                defval: 0.1,
                type: 'float',
                min: 0.001,
                max: 2e3,
              },*/
        ],
    },
});
