import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
// import { FromOpenAccount } from '~/modules/investment-consultant/openAccount/formOpenAccount'
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
export const Weng888_TopBar = memo(function Weng888_TopBar() {
    const { isPc, isPad } = useMedia();
    return (<div css={css `
        ${fill_horizontal_cross_center};
        justify-content: end;
        background-color: #12141c;
        border-bottom: 1px solid #2b2e38;
      `}>
      {/* {isPc && <FromOpenAccount customURL={customURL} />} */}
      {isPc && (<ChartingServerSwitch charting={store.charting} css={css `
            background-color: #1c1f29;
          `}/>)}
      {isPc && <UserAvatarAsDialogButton />}

      {!isPc && (<Preset_Topbar showLeftBurger componentsInRight={<div css={css `
                ${fill_horizontal_cross_center};
              `}>
              {/* {isPad && <FromOpenAccount customURL={customURL} />} */}
              <ChartingServerSwitch charting={store.charting}/>
              <UserAvatarAsDialogButton />
            </div>}/>)}
    </div>);
});
